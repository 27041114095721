<template>
	<a-card :bordered="false" class="borderless" :loading="loading">
		<div class="mb-3 dF jSB aC">
			<div>
				<div class="">{{ details.title || "" }}</div>
				<div class="block text-xl" style="font-weight: 600" v-if="totalAmount >= 0">
					{{ details.amountPrefix || ""
					}}{{
	totalAmount.toLocaleString("en-US", {
		useGrouping: true,
		minimumFractionDigits: 0,
		maximumFractionDigits: 2,
	})
}}{{ details.amountSuffix || "" }}
				</div>
			</div>
			<div v-if="details.displayToggle" data-html2canvas-ignore="true">
				<a-radio-group v-model="mode" :style="{ marginBottom: '8px' }" @change="updateChartMode(mode)">
					<a-radio-button value="countDetails" v-if="!details.hideCount">
						Count
					</a-radio-button>
					<a-radio-button value="valueDetails" v-if="!details.hideValue">
						Value
					</a-radio-button>
					<a-radio-button value="percentageDetails" v-if="!details.hidePercentage">
						%
					</a-radio-button>
				</a-radio-group>
			</div>
			<div v-if="details.displayChartType" data-html2canvas-ignore="true" class="dF aC jE">
				<span class="mr-2">Chart Type:</span>
				<a-select v-model="chartType" style="width: 80px" class="text-primary" :showArrow="true"
					@change="updateChartType(chartType)">
					<a-icon slot="suffixIcon" type="caret-down" class="text-primary" />
					<a-select-option value="bar" class="text-primary">
						Bar
					</a-select-option>
					<a-select-option value="line" class="text-primary">
						Line
					</a-select-option>
					<a-select-option v-if="details.option && details.option.chartColumn !== 'stack'" value="pie"
						class="text-primary">
						Pie
					</a-select-option>
				</a-select>
			</div>
			<div v-if="details.link" data-html2canvas-ignore="true">
				<a class="text-xs text-gray-500 text-primary" @click="viewDetails">
					{{ details.linkText || "View Details" }}
				</a>
			</div>
		</div>

		<eChartLine v-if="details.option" :option="finalOption" :key="details.title + details.link" />
	</a-card>
</template>

<script>
import eChartLine from "@/components/charts/eChartLine";
import { mapMutations } from "vuex";
import moment from "moment";

export default {
	components: {
		eChartLine,
	},
	props: {
		details: {
			type: Object,
			default: () => ({}),
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			mode: "countDetails",
			displayToggleKey: {
				countDetails: "count",
				valueDetails: "amount",
				percentageDetails: "percentage",
			},
			chartType: 'bar'
		};
	},
	computed: {
		instance() {
			return this.$store.state.instance;
		},

		corporateInstance() {
			return (this.instance && this.instance.projectType === 'corporate' && this.instance.children.length)
		},

		totalAmount() {
			if (!this.details.option) {
				return
			}
			if (this.details.totalAmount || this.details.totalAmount === 0) {
				return this.details.totalAmount;
			}
			let amount = this.details.amount;
			let totalDataCount = 0;
			if (!amount) {
				this.finalOption.series.forEach((series) => {
					amount = series.data.reduce((sum, nextData) => {
						totalDataCount++;
						if (this.details.option.chartColumn === "stack" || this.corporateInstance) {
							return sum + nextData;
						} else if (this.details.option.chartType === "pie") {
							return sum + nextData.value;
						} else {
							return sum + nextData[1];
						}
					}, amount);
				});
			}
			if (this.details.average) {
				return amount / totalDataCount;
			}
			return amount;
		},
		finalOption() {
			if (!this.details.option) {
				return
			}
			const options = this.getChartOption();

			if (["line", "bar"].includes(this.details.option.chartType)) {
				options.yAxis = {
					...this.details.option.yAxis,
					name: this.details.option.yAxis.name || "",
					nameLocation:
						this.details.option.yAxis.nameLocation || "center",
					nameGap: this.details.option.yAxis.nameGap || 50,
					nameTextStyle: {
						align: "center",
						verticalAlign: "bottom",
						fontWeight: "bold",
					},
					splitLine: this.details.option.yAxis.splitLine || {
						lineStyle: {
							type: "dashed",
						},
					},
				};
			}

			if (
				this.details.option.series.length <= 2 &&
				this.details.option.series.length > 0 &&
				!this.details.option.colorBy
			) {
				options.color = ["#f7941e", "#9693E8"];
			}

			if (this.details.option.grid) {
				options.grid = {
					...options.grid,
					...this.details.option.grid,
				};
			}

			if (!options.xAxis || !options.xAxis.data || !options.xAxis.data.length) {
				if (!options.yAxis) {
					options.yAxis = {
						nameGap: 200
					}
				} else {
					options.yAxis.nameGap = 200
				}
			}

			// if (this.details.title === "Pipeline by stage") {
			// 	console.log("options =>>>>", this.details.title, options);
			// }

			return options;
		},

		detailsPage() {
			return this.$route.path.includes("/details");
		},

		selectedProjects() {
			return this.$store.state.selectedProjects
		},
	},

	methods: {
		...mapMutations(["updateSelectedChart"]),

		updateChartMode(mode) {
			if (this.detailsPage) {
				if (
					this.details.chartDetails &&
					this.details.option &&
					this.details.option.series
				) {
					this.details.option.series = [];
				}
			} else {
				this.$emit("mode-change", mode);
			}
		},

		updateChartType(type) {
			if (this.detailsPage) {
				if (
					this.details.chartDetails &&
					this.details.option &&
					this.details.option.series
				) {
					this.details.option.series = [];
				}
			} else {
				this.$emit("type-change", type);
			}
		},

		viewDetails() {
			const data = { ...this.details };
			if (this.details.chartDetails) {
				data.option.series = [];
				data.option.xAxis.data = [];
			}
			this.updateSelectedChart(data);

			this.$router.push({
				path: this.details.link,
			});
		},

		getChartOption() {
			const option = this.setOptionDefaultValues();
			option.series = [];
			if (!option.xAxis) {
				option.xAxis = {};
			}
			option.xAxis.data = [];
			if (this.details.chartDetails) {
				for (let i = 0; i < this.details.chartDetails.length; i++) {
					const chartDetails = this.details.chartDetails[i];

					let chartDetailsData = JSON.parse(JSON.stringify(chartDetails.data))
					if (this.corporateInstance) {
						chartDetailsData = this.getSelectedProjectsData(chartDetailsData, (option.xAxis && option.xAxis.sortData || false))
					}

					if (option.chartColumn === "stack") {
						let uniqueStacks = [];
						for (let stackBar in chartDetailsData) {
							if (!option.xAxis.data.includes(stackBar)) {
								option.xAxis.data.push(stackBar);
							}
							for (let name in chartDetailsData[stackBar]) {
								if (!uniqueStacks.includes(name)) {
									uniqueStacks.push(name);
								}
							}
						}

						if (option.xAxis && option.xAxis.sortData && option.xAxis.data && option.xAxis.data.length) {
							option.xAxis.data = option.xAxis.data.sort((a, b) => {
								let aa = this.details.option.stringDate ? +moment(a).format("x") : a;
								let bb = this.details.option.stringDate ? +moment(b).format("x") : b;
								return aa - bb;
							});
							uniqueStacks = uniqueStacks.sort();
						}

						for (let stackBar of option.xAxis.data) {
							uniqueStacks.forEach((name) => {
								const index = option.series.findIndex((s) => {
									return s.name === name;
								});

								let stackBarName = stackBar

								if (index < 0) {
									if (this.details.itemByColor) {
										option.series.push({
											name: name,
											type: this.details.option.chartType || "bar",
											stack: "stack",
											color: this.details.itemByColor[
												name
											],
											data: [
												chartDetailsData[stackBarName] && chartDetailsData[stackBarName][name] && (this.details.displayToggle ? chartDetailsData[stackBarName][name][this.displayToggleKey[this.mode]] : chartDetailsData[stackBarName][
													name
												]) || 0,
											],
											emphasis: {
												focus: 'series'
											},
										});
									} else {
										option.series.push({
											name: name,
											type: this.details.option.chartType || "bar",
											stack: "stack",
											data: [
												chartDetailsData[stackBarName] && chartDetailsData[stackBarName][name] && (this.details.displayToggle ? chartDetailsData[stackBarName][name][this.displayToggleKey[this.mode]] : chartDetailsData[stackBarName][
													name
												]) || 0,
											],
											emphasis: {
												focus: 'series'
											},
										});
									}
								} else {
									option.series[index].data.push(
										chartDetailsData[stackBarName] && chartDetailsData[stackBarName][name] && (this.details.displayToggle ? chartDetailsData[stackBarName][name][this.displayToggleKey[this.mode]] : chartDetailsData[stackBarName][name]) || 0
									);
								}
							});
						}
					} else if (this.details.option.chartType === "pie") {
						const name =
							chartDetails.startDate &&
							chartDetails.endDate &&
							`${moment(chartDetails.startDate).format(
								"ll"
							)} - ${moment(chartDetails.endDate).format("ll")}`;
						option.series.push({
							name: name || "",
							type: this.details.option.chartType,
							data: [],
							radius: '50%',
							emphasis: {
								itemStyle: {
									shadowBlur: 10,
									shadowOffsetX: 0,
									shadowColor: 'rgba(0, 0, 0, 0.5)'
								}
							}
						});

						for (let key in chartDetails.data) {
							option.series[i].data.push({
								name: key,
								value: chartDetails.data[key]
							});
						}
					} else {
						const name =
							chartDetails.startDate &&
							chartDetails.endDate &&
							`${moment(chartDetails.startDate).format(
								"ll"
							)} - ${moment(chartDetails.endDate).format("ll")}`;
						option.series.push({
							name: name || "",
							type: this.details.option.chartType,
							data: [],
						});

						for (let value in chartDetails.data) {
							if (!option.xAxis.data.includes(value)) {
								option.xAxis.data.push(
									this.details.option.stringDate
										? +moment(value).format("x")
										: value
								);
							}
							option.series[i].data.push([
								this.details.option.chartType === "line" &&
									!this.details.option.timeSpan
									? moment(+value || value).format(
										this.details.option.dateFormat || "ll"
									)
									: value,
								this.details.displayToggle
									? chartDetails.data[value][
									this.displayToggleKey[this.mode]
									]
									: chartDetails.data[value],
							]);
						}

						if ((option.color || option.xAxis.sortData) && option.xAxis && option.xAxis.data && option.xAxis.data.length) {
							option.xAxis.data = option.xAxis.data.sort();
							option.series[i].data = option.series[i].data.sort(
								(a, b) =>
									a[0] < b[0] ? -1 : a[0] > b[0] ? 1 : 0
							);
						}
					}
				}

				if (
					this.details.option.chartType === "line" &&
					option.xAxis && option.xAxis.data &&
					option.xAxis.data.length
				) {
					option.xAxis.data = option.xAxis.data
						.sort()
						.map((date) =>
							this.details.option.timeSpan
								? date
								: moment(+date).format(
									this.details.option.dateFormat || "ll"
								)
						);

					option.series.forEach((series) => {
						if (option.colorBy) {
							series.colorBy = "data";
						}
						if (!this.details.option.timeSpan) {
							series.data = series.data.sort(
								(a, b) =>
									moment(a[0]).format("x") -
									moment(b[0]).format("x")
							);
						}
					});
				}
			}

			if (this.detailsPage && this.details.displayToggle) {
				const selectedMode = this.displayToggleKey[this.mode];
				option.yAxis.axisLabel = option.yAxis.axisLabel || {};
				option.yAxis.axisLabel.formatter =
					selectedMode === "count"
						? "{value}"
						: selectedMode === "amount"
							? "${value}"
							: "{value}%";

				option.yAxis.nameGap = selectedMode === "amount" ? 80 : 30;
				if (selectedMode === "count") {
					this.details.amountPrefix = "";
					this.details.amountSuffix = "";
				} else if (selectedMode === "amount") {
					this.details.amountPrefix = "$";
					this.details.amountSuffix = "";
				} else if (selectedMode === "percentage") {
					this.details.amountPrefix = "";
					this.details.amountSuffix = "%";
				}
			}

			return option;
		},

		setOptionDefaultValues() {
			if (!this.details.option) {
				return {}
			}
			const options = {
				...this.details.option,
				tooltip: {
					...(this.details.option && this.details.option.tooltip),
					trigger:
						(this.details.option && this.details.option.tooltip &&
							this.details.option.tooltip.trigger) ||
							this.details.option.chartType === "pie" ? "item" : "axis",
					axisPointer: {
						type:
							this.details.option.chartType === "line"
								? "line"
								: "shadow",
					},
				},
				legend: {
					...(this.details.option && this.details.option.legend),
					top:
						(this.details.option && this.details.option.legend &&
							this.details.option.legend.top) ||
						"bottom",
				},
				grid: {
					top: "5%",
					left: "7%",
					right: "0%",
					containLabel: true,
				}
			};

			if (this.corporateInstance) {
				options.legend.top = "auto";
				options.grid.top = "20%";
				options.grid.right = "7%";
				options.dataZoom = [
					{
						type: 'inside',
						start: 0,
						end: 100
					},
					{
						id: 'dataZoomX',
						type: 'slider',
						xAxisIndex: [0],
						filterMode: 'filter'
					},
					{
						id: 'dataZoomY',
						type: 'slider',
						yAxisIndex: [0],
						filterMode: 'empty'
					}
				]
			}

			return options;
		},

		getSelectedProjectsData(data, sortData) {
			const newData = {};
			for (let stackBar in data) {
				Object.keys(data[stackBar]).forEach(key => {
					if (this.selectedProjects.includes(key)) {
						if (!newData[stackBar]) newData[stackBar] = {};
						newData[stackBar][key] = data[stackBar][key]
					}
				})
			}

			if (sortData) {
				let sortedData = {};
				Object.keys(newData).sort().forEach(k => {
					sortedData[k] = newData[k]
				})
				return sortedData;
			}

			return newData;
		}
	},
	mounted() {
		if (!this.details.option) {
			return this.$router.go(-1)
		}
	}
};
</script>

<style>
</style>
